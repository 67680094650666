import {ArrowRightOutlined} from '@ant-design/icons';
import {Divider, Tag, Typography} from 'antd';
import {Fragment, useEffect} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import PageContent from '../../app/layout/PageContent';
import Analytics from '../../app/services/analytics/Analytics';
import appEvents from '../../app/services/analytics/appEvents';
import useAppStoreSelector from '../../app/store/useAppStoreSelector';
import useParam from '../../hooks/useParam';
import styles from './SelectOrg.module.css';

const {Title, Text} = Typography;

export default function SelectOrg() {
  const state = useAppStoreSelector((s) => s.state);
  const swtichActiveOrganization = useAppStoreSelector((s) => s.swtichActiveOrganization);
  const navigate = useNavigate();
  const redirectRef = useParam('state');
  const selectedrg = useParam('selectedOrg');

  useEffect(() => {
    Analytics.page(appEvents.SELECT_ORG_PAGE);
  }, []);

  if (selectedrg && selectedrg === state.user?.organization?.id) {
    return <Navigate to={redirectRef || '/'} />;
  }

  if (!(state.user?.organizations?.length > 1)) {
    return <Navigate to={redirectRef || '/'} />;
  }

  return (
    <PageContent
      noSider
      headerOptions={{
        renderHeaderOptions: () => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Tag
              style={{
                borderRadius: 12,
                cursor: 'pointer',
                fontSize: '.8rem',
                height: '1.5rem',
                lineHeight: '1rem',
                padding: '2px 6px',
              }}
              color="var(--primary-100)"
              onClick={() => {
                navigate('/workspaces');
              }}
            >
              Go to workspaces
            </Tag>
          </div>
        ),
      }}
      style={{height: 'calc(100vh - 48px)'}}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '3vh',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 24,
          }}
        >
          <img width={48} src="/assets/app_logo.png" alt="" />
          <Title level={3} style={{marginBottom: 2, marginTop: 12}}>
            Welcome to Houseware
          </Title>
          <Text style={{color: 'rgba(0, 0, 0, .6)'}}>
            Please choose an organization to log in
          </Text>
        </div>
        <div
          style={{
            padding: '12px 24px',
            background: 'white',
            width: '60%',
            borderRadius: 8,
            boxShadow: 'lightgrey 2px 2px 8px 2px',
            maxHeight: 'calc(100vh - 300px)',
            overflowY: 'auto',
          }}
        >
          <Title level={5} style={{margin: 0}}>
            Organizations for {state.user?.email}
          </Title>
          <Divider style={{margin: '12px 0px'}} />
          <>
            {state.user?.organizations?.map((org) => (
              <Fragment key={org.id}>
                <div
                  className={styles.orgCard}
                  onClick={() => {
                    if (org.id === state.user?.organization?.id) {
                      navigate(redirectRef || '/');
                      return;
                    }
                    const newURL = new URL(window.location.origin + (redirectRef || '/'));
                    newURL.searchParams.set('selectedOrg', org.id);
                    navigate(newURL.href.replace(window.location.origin, ''));
                    swtichActiveOrganization(org.id, () => navigate(redirectRef || '/'));
                  }}
                >
                  <img width={32} src={org.logo_url || '/assets/app_logo.png'} alt="" />
                  <Title level={5} style={{margin: 0, marginLeft: 8}}>
                    {org.name}
                  </Title>
                  <div
                    style={{
                      display: 'flex',
                      flexGrow: 1,
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {org.id === state.user?.organization?.id && (
                      <Tag color="var(--primary-100)" style={{marginRight: 12}}>
                        Current Organization
                      </Tag>
                    )}
                    <ArrowRightOutlined />
                  </div>
                </div>
                <Divider style={{margin: '12px 0px'}} />
              </Fragment>
            ))}
          </>
        </div>
      </div>
    </PageContent>
  );
}
