import * as React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import DomainRedirect from '../../pages/Auth/DomainRedirect';
import useAppStoreSelector from '../store/useAppStoreSelector';

import AuthenticateCallback from '../../pages/Auth/AuthCallback';
import AuthRedirect from '../../pages/Auth/AuthRedirect';
import SelectOrg from '../../pages/Auth/SelectOrg';
import ConnectWarehouseRoutes from '../../pages/Connections/ConnectWarehouse';

const AuthenticatedRoutesWithLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "authenticated-routes" */
    './AuthenticatedRoutesWithLayout'
  )
);

export default function AllRoutes() {
  const {user, orgUsers} = useAppStoreSelector((s) => s.state);
  const isCurrentUserAdmin =
    orgUsers?.find((item) => item.id === user.id)?.user_role === 'admin';

  return (
    <Routes>
      <Route path="/auth" element={<AuthenticateCallback />} />
      <Route
        path="/connect-warehouse/*"
        element={
          isCurrentUserAdmin ? <ConnectWarehouseRoutes /> : <Navigate to="/workspaces" />
        }
      />
      <Route path="/auth_redirect" element={<AuthRedirect />} />
      <Route path="/domain_redirect" element={<DomainRedirect />} />
      <Route path="/select-org" element={<SelectOrg />} />
      <Route path="*" element={<AuthenticatedRoutesWithLayout />} />
    </Routes>
  );
}
