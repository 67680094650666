import {
  DownOutlined,
  FieldTimeOutlined,
  HomeOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  SolutionOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {Button, Divider, Flex, Image, Layout, Tooltip, Typography} from 'antd';
import {CSSProperties, memo, ReactNode, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Recents from '../../../features/block/components/Recents';
import Analytics from '../../services/analytics/Analytics';
import appEvents, {
  appEventsPA,
  getPaFormattedEvent,
} from '../../services/analytics/appEvents';
import {AppState} from '../../store/types';
import AccountDropDown from '../AccountDropDown';
import moduleStyles from './AppSider.module.css';

const {Sider} = Layout;

const styles: Record<string, CSSProperties> = {
  siderRoot: {
    overflow: 'hidden',
    maxHeight: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    paddingTop: '0.9rem',
    zIndex: 99,
    overflowY: 'auto',
    borderRight: '1px solid var(--border)',
  },

  divider: {
    minWidth: '90%',
    width: '90%',
    margin: '.2rem .8rem',
    borderWidth: '.5px',
  },

  navigationItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    padding: '0px 8px',
  },
  navItemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const appSiderWidth = {
  collapsedWidth: '14vw',
};

type NavItem =
  | {
      type?: 'nav';
      key: string;
      id: string;
      alwaysActive?: boolean;
      label: string;
      tooltip: string;
      disabled?: boolean;
      hide?: boolean;
      icon: React.ReactNode;
      eventName?: {
        name: string;
        category: string;
      };
      render?: () => ReactNode;
    }
  | {type: 'divider'; hide?: boolean; key: string};

const iconStyles = {fontSize: '1.3rem'};

function Navdivider() {
  return (
    <Divider
      style={{
        ...styles.divider,
        borderColor: 'var(--border)',
      }}
    />
  );
}

type PropsAppSiderComponent = {
  currentUser: AppState['user'];
  isCurrentUserAdmin: boolean;
};

function AppSiderComponent({currentUser, isCurrentUserAdmin}: PropsAppSiderComponent) {
  const path = useLocation().pathname;
  const currentPage = `/${path.split('/')[1]}`;
  const navigate = useNavigate();

  const topNavigationItems = useMemo(() => {
    const primaryNavigationItems = [
      {
        key: '/workspaces',
        label: 'Workspaces',
        tooltip: 'View Favorite, Created, and Shared Workspaces',
        icon: <HomeOutlined style={iconStyles} />,
        eventName: appEvents.SIDEBAR_WORKSPACES_CLICKED,
        id: 'intercom_workspaces_identifier',
      },
      {
        type: 'divider',
        key: 'div4',
      },
      {
        key: '/events',
        label: 'Events',
        tooltip: 'Explore Predefined and Custom Events',
        icon: <SolutionOutlined style={iconStyles} />,
        id: 'intercom_events_identifier',
      },
      {
        key: '/cohorts',
        label: 'Cohorts',
        tooltip: 'Browse All User Cohorts',
        icon: <TeamOutlined style={iconStyles} />,
        id: 'intercom_cohorts_identifier',
      },
      {
        key: '/user-activity',
        label: 'User Activity',
        tooltip: 'Explore User Properties and Event Paths',
        icon: <FieldTimeOutlined style={iconStyles} />,
        id: 'intercom_user_activity_identifier',
      },
    ];

    if (isCurrentUserAdmin) {
      primaryNavigationItems.push(
        {type: 'divider', key: 'div5'},
        {
          key: '/admin',
          label: 'Admin',
          tooltip: 'Access Admin Controls and Settings',
          icon: <SettingOutlined style={iconStyles} />,
          eventName: appEvents.ADMIN_CLICKED,
          id: 'intercom_admin_identifier',
        }
      );
    }

    return primaryNavigationItems.map((item) => {
      if (item.type === 'divider') {
        return <Navdivider key={item.key} />;
      }

      const {key, icon, label, eventName, tooltip, id} = item;

      return (
        <Tooltip title={tooltip} placement="right" key={key}>
          <div
            id={id}
            className={`${moduleStyles.navItem} ${
              key === currentPage && moduleStyles.navItemActive
            }`}
            onClick={(e) => {
              if (eventName)
                Analytics.track({
                  name: eventName.name,
                  properties: {
                    event_category: 'General',
                    event_action: 'Engagement - Sidebar',
                  },
                });
              if (e.ctrlKey || e.metaKey) return window.open(key, '_blank');
              navigate(key);
            }}
            key={key}
          >
            <div className={moduleStyles.navItemIcon}>{icon}</div>
            <Typography.Text className={moduleStyles.navItemText}>
              {label}
            </Typography.Text>
          </div>
        </Tooltip>
      );
    });
  }, [isCurrentUserAdmin, currentPage, navigate]);

  const extraNavItems = useMemo(() => {
    const housewareOptions: NavItem[] = [
      {
        key: 'documentation',
        tooltip: 'Documentation',
        label: 'Docs',
        icon: <QuestionCircleOutlined style={iconStyles} />,
        eventName: appEvents.DOCUMENTATION_CLICKED,
        id: 'intercom_documentation_identifier',
      },
    ];
    return housewareOptions.map((item) => {
      if (item.type === 'divider') {
        return <Navdivider key={item.key} />;
      }
      const {key, icon, label, disabled, eventName, tooltip} = item;
      return (
        <Tooltip title={tooltip} placement="right" key={key}>
          <div
            className={`${moduleStyles.navItem} ${
              disabled && moduleStyles.navItemDisabled
            }`}
            onClick={() => {
              if (disabled) return;
              if (eventName) {
                Analytics.track({
                  name: eventName.name,
                  properties: {
                    event_category: 'General',
                    event_action: 'Engagement - Sidebar',
                  },
                });
              }
              if (key === 'documentation') {
                window.open('https://docs.houseware.io/', '_blank');
              }
            }}
            key={key}
          >
            <div className={moduleStyles.navItemIcon}>{icon}</div>
            <div className={moduleStyles.navItemText}>{label}</div>
          </div>
        </Tooltip>
      );
    });
  }, []);

  return (
    <Sider
      breakpoint="sm"
      collapsible
      style={styles.siderRoot}
      collapsed={true}
      trigger={null}
      {...appSiderWidth}
    >
      <div style={styles.navigationItems}>
        <div style={styles.navItemContainer}>
          <AccountDropDown>
            <Flex
              align="center"
              gap={8}
              style={{
                cursor: 'pointer',
                width: '100%',
              }}
            >
              <Image
                onClick={() => Analytics.track(appEvents.ORG_IMAGE_CLICKED)}
                preview={false}
                width={'2rem'}
                height={'2rem'}
                alt={currentUser?.organization?.name + ' logo'}
                src={currentUser?.organization?.logo_url || '/assets/app_logo.png'}
                id="header-logo"
                style={{
                  borderRadius: '4px',
                }}
              />

              <Flex
                gap={8}
                justify="space-between"
                flex={1}
                align="center"
                style={{width: '100%'}}
              >
                <Typography.Title level={5}>
                  {currentUser?.organization?.name}
                </Typography.Title>
                <DownOutlined
                  style={{color: 'var(--secondary-text)', fontSize: '1rem'}}
                />
              </Flex>
            </Flex>
          </AccountDropDown>

          <Tooltip title="Create New Visualization" placement="right">
            <Button
              type="primary"
              block
              icon={<PlusOutlined />}
              onClick={() => {
                const CREATE_VISUALIZATION_CLICKED = getPaFormattedEvent(
                  appEventsPA.CREATE_CLICKED
                );
                Analytics.track(CREATE_VISUALIZATION_CLICKED);
                navigate(`/visualizations/new`);
              }}
            >
              Create
            </Button>
          </Tooltip>
          <Recents
            contentClassName={`${moduleStyles.navItem} ${moduleStyles.marginBottomLarge}`}
          />
          {topNavigationItems}
        </div>
        <div style={styles.navItemContainer}>
          <Navdivider />
          {extraNavItems}
        </div>
      </div>
    </Sider>
  );
}

const AppSider = memo(AppSiderComponent);
export default AppSider;
