import {Layout, Space, Typography} from 'antd';

const {Footer} = Layout;

export default function AppFooter() {
  return (
    <Footer style={{textAlign: 'right', padding: '4px 24px'}}>
      <Space size="middle">
        <a href="https://www.houseware.io/utility/privacy">Privacy ·</a>
        <a href="https://www.houseware.io/utility/terms">Terms ·</a>
        <Typography.Text>
          © {new Date().getFullYear()} CMD CTR, Inc. All Rights Reserved
        </Typography.Text>
      </Space>
    </Footer>
  );
}
