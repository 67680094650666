import {ArrowRightOutlined} from '@ant-design/icons';
import {Button, Card, Col, Image, Row, Space, Typography} from 'antd';
import * as React from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import AccountLayout from '../../app/layout/AccountLayout/AccountLayout';
import Analytics from '../../app/services/analytics/Analytics';
import appEvents from '../../app/services/analytics/appEvents';
import useParam from '../../hooks/useParam';
import SnowflakeSecureDataSharing from './SnowflakeSecureDataSharing/SnowflakeSecureDataSharing';

const ChooseYourWarehouse = React.lazy(() => import('./ChooseYourWarehouse'));

const HousewareProvidedWarehouse = React.lazy(() => import('./HousewareProvidedSetup'));
const UserWarehouseDestination = React.lazy(() =>
  import('./BringYourOwnWarehouse/UserWarehouseDestination')
);

const {Text, Title} = Typography;

const styles = {
  warehouseCover: {
    padding: '8px',
    width: '100%',
    height: 220,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export function ConnectWarehouse() {
  const navigate = useNavigate();
  const redirectRef = useParam('state');

  function useOwnWarehouseSetup() {
    navigate('/connect-warehouse/bring-your-warehouse');
  }

  function connectHousewareProvidedSetup() {
    const url = redirectRef
      ? `/connect-warehouse/snowflake-secure-data-sharing?redirectUrl=${redirectRef}`
      : `/connect-warehouse/snowflake-secure-data-sharing`;

    navigate(url);
  }

  return (
    <AccountLayout>
      <Space
        direction="vertical"
        size="large"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          minHeight: 'calc(100vh - 120px)',
          width: '100%',
        }}
      >
        <div>
          <Title level={3} style={{marginBottom: 6, color: 'var(--primary-100)'}}>
            Your data, always yours
          </Title>
          <Text>
            Bring your own warehouse or choose a powered by Snowflake secure data sharing
            option
          </Text>
        </div>
        <Row gutter={[{md: 32, lg: 48}, 32]} align="middle" justify="center">
          <Col sm={20} md={14} lg={10}>
            <Card
              hoverable
              style={{width: '100%'}}
              title={
                <Text style={{color: 'var(--primary-100)'}}>
                  Plug Houseware to work on your Cloud data warehouse
                </Text>
              }
              cover={
                <div style={styles.warehouseCover}>
                  <Image
                    width={180}
                    preview={false}
                    alt="plug Houseware to work on your warehouse"
                    src="/assets/bring_your_own_warehouse.png"
                  />
                </div>
              }
              actions={[
                <Button type="primary" icon={<ArrowRightOutlined />}>
                  Connect warehouse
                </Button>,
              ]}
              onClick={useOwnWarehouseSetup}
            >
              <Text style={{display: 'block', minHeight: '10rem', maxHeight: '10rem'}}>
                Choose this option if your organization already has an existing cloud data
                warehouse setup. Houseware directly utilizes the data from your warehouse
                for any app functionalities. Houseware currently supports Snowflake and
                BigQuery.
              </Text>
            </Card>
          </Col>
          <Col sm={20} md={14} lg={10}>
            <Card
              hoverable
              style={{width: '100%'}}
              title={
                <Text style={{color: 'var(--primary-100)'}}>
                  Use Snowflake Secure Data Sharing
                </Text>
              }
              cover={
                <div style={styles.warehouseCover}>
                  <Image
                    width={190}
                    preview={false}
                    alt="use a Houseware-powered warehouse"
                    src="/assets/brand_logo/snowflake_data_cloud.png"
                  />
                </div>
              }
              actions={[
                <Button type="primary" icon={<ArrowRightOutlined />}>
                  Use secure data sharing
                </Button>,
              ]}
              onClick={() => {
                Analytics.track(appEvents.SETUP_MMM_CLICKED);
                connectHousewareProvidedSetup();
              }}
            >
              <Text style={{display: 'block', minHeight: '10rem', maxHeight: '10rem'}}>
                With Secure Data Sharing, no actual data is copied or transferred between
                accounts. All sharing uses Snowflake’s services layer and metadata store.
                Because no data is copied or exchanged, Secure Data Sharing setup is quick
                and easy for providers and access to the shared data is near-instantaneous
                for consumer
              </Text>
            </Card>
          </Col>
        </Row>
      </Space>
    </AccountLayout>
  );
}

export default function ConnectWarehouseRoutes() {
  return (
    <Routes>
      <Route
        path="bring-your-warehouse/:destination"
        element={<UserWarehouseDestination />}
      />
      <Route path="bring-your-warehouse" element={<ChooseYourWarehouse />} />
      <Route path="build-houseware-warehouse" element={<HousewareProvidedWarehouse />} />
      <Route
        path="snowflake-secure-data-sharing"
        element={<SnowflakeSecureDataSharing />}
      />
      <Route index element={<ConnectWarehouse />} />
    </Routes>
  );
}
