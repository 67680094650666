import {Layout} from 'antd';
import {memo, ReactNode} from 'react';

const {Header} = Layout;

export type PageHeaderProps = {
  renderHeaderOptions?: () => ReactNode;
};

export const headerHeight = '3.5rem';

function PageHeaderComponent(props: PageHeaderProps) {
  const {renderHeaderOptions} = props;

  return (
    <Header
      style={{
        width: `100%`,
        minHeight: headerHeight,
        height: 'max-content',
        padding: '0 12px',
        borderBottom: '1px solid var(--border)',
      }}
    >
      {typeof renderHeaderOptions === 'function' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: headerHeight,
            height: 'max-content',
          }}
        >
          {renderHeaderOptions()}
        </div>
      )}
    </Header>
  );
}

const PageHeader = memo(PageHeaderComponent);

export default PageHeader;
