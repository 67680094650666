// theme/themeConfig.ts
import type {ThemeConfig} from 'antd';
import {AppThemeModeType, AppThemeType} from './AppThemeProvider';

type ThemeColorTypes = {
  '--primary-110': string;
  '--primary-100': string;
  '--primary-90': string;
  '--primary-60': string;
  '--primary-30': string;
  '--primary-15': string;
  '--primary-10': string;
  '--primary-5': string;
  '--primary-0': string;
  '--secondary-100': string;
  '--secondary-90': string;
  '--secondary-60'?: string;
  '--secondary-30'?: string;
  '--secondary-15'?: string;
  '--secondary-10': string;
  '--secondary-5'?: string;
  '--tertiary-100': string;
  '--warning-100': string;
};

export const APP_FONT_FAMILY = 'Inter, sans-serif';

export const themes: Record<AppThemeType, Record<AppThemeModeType, ThemeColorTypes>> = {
  housewarePink: {
    light: {
      // '--primary-110': '#6b205a',
      // //Base color
      // '--primary-100': '#6d1d4f',
      // '--primary-90': '#7d365f',
      // '--primary-60': '#b17e9a',
      // '--primary-30': '#d4b9c6',
      // '--primary-15': '#e3d0d9',
      // '--primary-10': '#f1e7ec',
      // '--primary-5': '#f8f3f5',

      '--primary-110': '#3d52e1',
      '--primary-100': '#435cfe',
      '--primary-90': '#5a72ff',
      '--primary-60': '#8e9aff',
      '--primary-30': '#bcc6ff',
      '--primary-15': '#d8e0ff',
      '--primary-10': '#ebf0ff',
      '--primary-5': '#f7f9ff',
      '--primary-0': '#f7f9ff40',

      // '--secondary-100': '#FD36A3',
      // '--secondary-90': '#55853b',
      // '--secondary-10': '#e9efe5',

      // '--secondary-110': '#e03091',
      '--secondary-100': '#FD36A3',
      '--secondary-90': '#ff5fb3',
      // '--secondary-60': '#ff94cc',
      // '--secondary-30': '#ffcae5',
      // '--secondary-15': '#ffe3f0',
      '--secondary-10': '#fff0f7',
      // '--secondary-5': '#fff8fb',

      '--tertiary-100': '#E2BC52',
      '--warning-100': '#FFC107',
    },
  },

  longbottomBlue: {
    light: {
      '--primary-110': '#3144d9',
      '--primary-100': '#425eff',
      '--primary-90': '#536eff',
      '--primary-60': '#879cff',
      '--primary-30': '#bbc8ff',
      '--primary-15': '#d5ddff',
      '--primary-10': '#dde4ff',
      '--primary-5': '#e4eaff',
      '--primary-0': '#e4eaff40',

      '--secondary-100': '#a736ec',
      '--secondary-90': '#ae4aee',
      '--secondary-60': '#c586f4',
      '--secondary-30': '#e0c3fa',
      '--secondary-15': '#efe1fd',
      '--secondary-10': '#f5ebfe',
      '--secondary-5': '#faf5ff',

      '--tertiary-100': '#00a455',
      '--warning-100': '#FFC107',
    },
  },
};

type NaturalColors =
  | '--neutral-100'
  | '--neutral-95'
  | '--neutral-90'
  | '--neutral-85'
  | '--neutral-80'
  | '--neutral-75'
  | '--neutral-70'
  | '--neutral-65'
  | '--neutral-60'
  | '--neutral-55'
  | '--neutral-50'
  | '--neutral-45'
  | '--neutral-40'
  | '--neutral-35'
  | '--neutral-30'
  | '--neutral-25'
  | '--neutral-20'
  | '--neutral-15'
  | '--neutral-10'
  | '--neutral-5'
  | '--neutral-0';

type NaturalColorsHex = `#${string}`;

const naturalColorsMap: Record<
  AppThemeModeType,
  Record<NaturalColors, NaturalColorsHex>
> = {
  light: {
    '--neutral-100': '#1c273b',
    '--neutral-95': '#282f44',
    '--neutral-90': '#34384c',
    '--neutral-85': '#404155',
    '--neutral-80': '#4b4a5d',
    '--neutral-75': '#575466',
    '--neutral-70': '#635e6f',
    '--neutral-65': '#6e6878',
    '--neutral-60': '#7a7281',
    '--neutral-55': '#857d8a',
    '--neutral-50': '#918894',
    '--neutral-45': '#9c939d',
    '--neutral-40': '#a79ea7',
    '--neutral-35': '#b3aab1',
    '--neutral-30': '#beb5bb',
    '--neutral-25': '#c9c1c6',
    '--neutral-20': '#d4cdd1',
    '--neutral-15': '#dfd9dc',
    '--neutral-10': '#e9e6e7',
    '--neutral-5': '#f4f2f3',
    '--neutral-0': '#ffffff',
  },
};

const housewareColors = {
  '--border': 'var(--neutral-10)',
  '--dark-border': 'var(--neutral-20)',
  '--background': 'var(--neutral-0)',
  '--dark-foreground': 'var(--neutral-10)',
  '--light-foreground': 'var(--neutral-5)',
  '--disabled-bg': 'var(--neutral-10)',

  '--danger': '#cf1322',
  '--success': '#3f8600',

  '--heading-text': 'var(--neutral-100)',
  '--primary-text': 'var(--neutral-80)',
  '--secondary-text': 'var(--neutral-50)',
  '--tertiary-text': 'var(--neutral-30)',
  '--link-text': 'var(--primary-100)',
  '--disabled-text': 'var(--neutral-30)',
};

export const getThemeColors = ({mode}: {mode: AppThemeModeType}) => {
  return {
    ...naturalColorsMap[mode],
    ...housewareColors,
    ...themes.longbottomBlue[mode],
  };
};

export function getTheme(themeMode: AppThemeModeType): ThemeConfig {
  const themeAppColors = getThemeColors({mode: themeMode});

  const themeConfig: ThemeConfig = {
    token: {
      // Seed Token
      colorPrimary: themeAppColors['--primary-100'],
      borderRadius: 8,
      fontSize: 14,
      colorBorderSecondary: themeAppColors['--border'],
      colorSplit: themeAppColors['--border'],
      colorText: themeAppColors['--primary-text'],
      colorTextHeading: themeAppColors['--heading-text'],
      colorTextDescription: themeAppColors['--secondary-text'],
      fontFamily: APP_FONT_FAMILY,
      colorLink: themeAppColors['--link-text'],
      colorTextDisabled: themeAppColors['--disabled-text'],
      colorError: themeAppColors['--danger'],
      colorBgContainerDisabled: themeAppColors['--disabled-bg'],
      colorInfo: themeAppColors['--primary-100'],
      wireframe: false,
      colorTextBase: themeAppColors['--primary-text'],
    },
    components: {
      Typography: {
        titleMarginBottom: 'revert',
        colorTextDescription: themeAppColors['--secondary-text'],
        colorLink: themeAppColors['--link-text'],
        colorLinkHover: themeAppColors['--link-text'],
      },
      Menu: {
        activeBarBorderWidth: 0,
        horizontalItemHoverBg: themeAppColors['--background'],
        itemBg: themeAppColors['--background'],
        itemSelectedBg: themeAppColors['--link-text'],
        itemSelectedColor: themeAppColors['--primary-10'],
        itemHeight: 30,
        itemBorderRadius: 6,
        groupTitleColor: themeAppColors['--tertiary-text'],
        groupTitleFontSize: 12,
        itemPaddingInline: 2,
        itemMarginInline: 2,
        iconMarginInlineEnd: 8,
      },
      Avatar: {
        colorTextPlaceholder: themeAppColors['--secondary-60'],
      },
      Button: {
        borderColorDisabled: themeAppColors['--border'],
        dangerColor: themeAppColors['--background'],
        primaryShadow: 'none',
        primaryColor: themeAppColors['--primary-5'],
        colorPrimary: themeAppColors['--link-text'],
        borderRadius: 6,
        paddingInline: 12,
        fontWeight: 500,
        colorError: themeAppColors['--danger'],
        defaultBg: themeAppColors['--background'],
        defaultColor: themeAppColors['--primary-text'],
        defaultBorderColor: themeAppColors['--border'],
        colorLink: themeAppColors['--link-text'],
      },
      Segmented: {
        itemActiveBg: themeAppColors['--background'],
        itemSelectedBg: themeAppColors['--background'],
        itemColor: themeAppColors['--primary-text'],
      },
      Card: {
        colorBgContainer: themeAppColors['--background'],
        actionsLiMargin: '0px',
      },
      Select: {
        clearBg: themeAppColors['--background'],
        selectorBg: themeAppColors['--background'],
        colorBgElevated: themeAppColors['--background'],
        colorPrimaryHover: themeAppColors['--border'],
        colorPrimary: themeAppColors['--border'],
        optionSelectedBg: themeAppColors['--link-text'],
        optionSelectedColor: themeAppColors['--primary-10'],
        optionActiveBg: themeAppColors['--dark-foreground'],
        colorBorder: themeAppColors['--border'],
        borderRadiusSM: 4,
      },
      Radio: {
        buttonBg: themeAppColors['--background'],
        buttonColor: themeAppColors['--primary-text'],
        buttonCheckedBg: themeAppColors['--primary-10'],
        buttonSolidCheckedBg: themeAppColors['--primary-10'],
        buttonSolidCheckedActiveBg: themeAppColors['--dark-foreground'],
        buttonSolidCheckedColor: themeAppColors['--primary-90'],
        buttonSolidCheckedHoverBg: themeAppColors['--primary-10'],

        // On Hover over selected
        colorBorder: themeAppColors['--border'],
        borderRadius: 5,
      },
      Popover: {
        colorBgElevated: themeAppColors['--background'],
      },
      Input: {
        activeBorderColor: themeAppColors['--border'],
        hoverBorderColor: themeAppColors['--border'],
        colorBorder: themeAppColors['--border'],
        activeShadow: 'none',
        colorPrimaryHover: themeAppColors['--tertiary-text'],
        colorPrimary: themeAppColors['--secondary-text'],
        colorBgContainer: themeAppColors['--background'],
        colorTextPlaceholder: themeAppColors['--tertiary-text'],
      },
      Tooltip: {
        colorBgSpotlight: themeAppColors['--neutral-90'],
        fontSize: 12,
        borderRadius: 8,
        colorText: themeAppColors['--neutral-10'],
        boxShadow: 'none',
      },
      Dropdown: {
        colorBgElevated: themeAppColors['--background'],
        paddingContentHorizontal: 10,
      },
      Divider: {
        colorText: themeAppColors['--tertiary-text'],
        colorTextHeading: themeAppColors['--tertiary-text'],
        fontSize: 4,
        fontSizeLG: 10,
        margin: 0,
        marginLG: 0,
      },
      Drawer: {
        colorBgElevated: themeAppColors['--background'],
      },
      Modal: {
        contentBg: themeAppColors['--background'],
        headerBg: themeAppColors['--background'],
      },
      Table: {
        borderColor: themeAppColors['--border'],
        headerBg: themeAppColors['--background'],
        colorBgContainer: themeAppColors['--background'],
        rowHoverBg: themeAppColors['--dark-foreground'],
        headerSplitColor: themeAppColors['--background'],
        lineWidth: 0.1,
        headerColor: themeAppColors['--secondary-text'],
      },
      Tag: {
        defaultBg: themeAppColors['--dark-foreground'],
        defaultColor: themeAppColors['--primary-text'],
        colorBorder: themeAppColors['--border'],
      },
      Layout: {
        siderBg: themeAppColors['--background'],
        bodyBg: themeAppColors['--background'],
        footerBg: themeAppColors['--background'],
        headerBg: themeAppColors['--background'],
      },
      Tabs: {
        itemColor: themeAppColors['--tertiary-text'],
        itemHoverColor: themeAppColors['--primary-text'],
        horizontalMargin: '0px',
      },
    },
  };

  return themeConfig;
}
