import {message} from 'antd';
import {useEffect} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import PageLoader from '../../app/layout/PageLoader';
import useAppStoreSelector from '../../app/store/useAppStoreSelector';
import useParam from '../../hooks/useParam';

let didInit = false;

export default function AuthCallback() {
  const code = useParam('code');
  const navigate = useNavigate();
  // param state is the actual url slug that user was visiting before auth
  const redirectRef = useParam('state');
  const state = useAppStoreSelector((s) => s.state);
  const login = useAppStoreSelector((s) => s.login);

  const {user, err, integrationFetchError} = state;

  useEffect(() => {
    if (code && !user && !err && !integrationFetchError) {
      const currentURL = new URL(window.location);
      if (!didInit) {
        const authToken = code;
        login(authToken);
        didInit = true;
        // auth_token is only one time consumable, remove it from param asap
        currentURL.searchParams.delete('code');
        navigate(currentURL.href.replace(currentURL.origin, ''));
      } else {
        navigate(currentURL.href.replace(currentURL.origin, ''));
      }
    }
  }, [code, user, err, login, integrationFetchError, navigate]);

  useEffect(() => {
    if (err) {
      message.error(err);
    }
  }, [err]);

  if (user) {
    if (user?.organizations?.length > 1) {
      return <Navigate to={`/select-org?state=${redirectRef}`} />;
    }
    if (redirectRef) return <Navigate to={redirectRef} />;
    return <Navigate to="/" />;
  }
  if (!code) return <Navigate to="/" />;

  return <PageLoader style={{width: '100vw', height: '100vh'}} />;
}
