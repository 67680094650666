export const userSnowflakeSecureDataSharingScripts = `--need to go to ACCOUNTADMIN
set share_name = 'houseware_analytics_db_share';
CREATE SHARE identifier($share_name);

use database identifier($db_name);
use schema identifier($schema_name);

GRANT USAGE ON DATABASE identifier($db_name) TO SHARE identifier($share_name);
GRANT USAGE ON SCHEMA identifier($schema_name) TO SHARE identifier($share_name);
GRANT SELECT ON TABLE "ALLEVENTS" TO SHARE identifier($share_name);

DESC SHARE identifier($share_name);

--Direct data sharing is only allowed within the same region. 
ALTER SHARE identifier($share_name) ADD ACCOUNTS=XG59586;`;
