import {RightOutlined} from '@ant-design/icons';
import {useQuery} from '@tanstack/react-query';
import {Flex, List, Popover, Spin, Tag, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import {getRecentWorkspacesAPI} from '../../../app/apis/block';
import {blocksQueryKeys} from '../../../app/store/queryKeyFactory';

export default function Recents({contentClassName}: {contentClassName: string}) {
  const navigate = useNavigate();
  const {
    data: recentWorkspaces,
    isPending: isLoadingRecentWorkspaces,
    refetch,
  } = useQuery({
    queryKey: blocksQueryKeys.recents(),
    queryFn: () => getRecentWorkspacesAPI(),
    gcTime: 1000 * 30, // refetch frequently
  });

  return (
    <Popover
      placement="rightTop"
      onOpenChange={(open) => {
        if (open) {
          refetch();
        }
      }}
      content={
        isLoadingRecentWorkspaces ? (
          <Flex
            style={{
              height: '40vh',
              width: '100%',
            }}
            justify="center"
            align="center"
          >
            <Spin />
          </Flex>
        ) : (
          <List
            style={{maxHeight: '40vh', overflowY: 'auto'}}
            itemLayout="horizontal"
            dataSource={recentWorkspaces}
            renderItem={(item: any, index) => {
              return (
                <List.Item
                  key={`${item.block_uuid} ${index}`}
                  onClick={() => {
                    if (item.block_type === 'workspace' || item.block_type === 'app') {
                      if (item.parent_block_uuid) {
                        navigate(
                          `/workspaces/${item.parent_block_uuid}/${item.block_uuid}`
                        );
                        return;
                      }
                      navigate(`/workspaces/${item.block_uuid}`);
                      return;
                    }
                    navigate(`/visualizations/${item.block_uuid}`);
                  }}
                  style={{cursor: 'pointer'}}
                >
                  <List.Item.Meta
                    avatar={null}
                    title={`${
                      item?.block_properties?.emoji
                        ? item?.block_properties?.emoji + ' '
                        : ''
                    }${item?.block_properties?.title}`}
                    description={
                      <Tag color="var(--secondary-100)">
                        {item.block_type === 'app' ? 'workspace' : item.block_type}
                      </Tag>
                    }
                  />
                </List.Item>
              );
            }}
          />
        )
      }
      title="Recently viewed, jump back in"
    >
      <div className={contentClassName} key={'Recent'}>
        <Typography.Text>
          Recently viewed <RightOutlined />
        </Typography.Text>
      </div>
    </Popover>
  );
}
