import {CaretDownFilled} from '@ant-design/icons';
import {Image, Layout} from 'antd';
import AppFooter from '../../layout/AppFooter/AppFooter';
import useAppStoreSelector from '../../store/useAppStoreSelector';
import AccountDropDown from '../AccountDropDown';
import styles from './AccountLayout.module.css';

const {Content, Header} = Layout;

export default function AccountLayout({children, style = {}}) {
  const state = useAppStoreSelector((s) => s.state);

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Header
        style={{
          background: '#fff',
          position: 'fixed',
          top: 0,
          zIndex: 1,
          width: '100%',
          height: '64px',
          padding: '0 24px',
          borderBottom: '1px solid rgba(57, 35, 150, 0.2)',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '64px',
            cursor: 'pointer',
          }}
        >
          <AccountDropDown
            dropDownStyle={{
              width: '100%',
              minWidth: 180,
            }}
          >
            <div className={styles.header}>
              <Image
                style={{marginLeft: 8}}
                preview={false}
                width={30}
                height={30}
                alt={state.user?.organization?.name + ' logo'}
                src={state?.user?.organization?.logo_url || '/assets/app_logo.png'}
                id="header-logo"
              />
              <CaretDownFilled style={{marginLeft: '6px', color: 'var(--primary-100)'}} />
            </div>
          </AccountDropDown>
        </div>
      </Header>
      <Content
        style={{
          padding: 24,
          margin: '56px 0px 0px 0px',
          minHeight: 'calc(100vh - 100px)',
          background: '#fff',
          display: 'flex',
          ...style,
        }}
      >
        {children}
      </Content>
      <AppFooter />
    </Layout>
  );
}
